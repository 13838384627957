// 一般情况调查问卷;
export const survey = {
  title: "",
  logoPosition: "right",
  showCompletedPage: false, //问卷完成后显示完成提示
  // navigateToUrl: "http://192.168.110.27:8081/#/fillquestionnaire", //问卷完成后跳转地址
  checkErrorsMode: "onValueChanged", //答案改变时立即校验
  widthMode: "responsive",
  width: "500px",
  pages: [
    {
      name: "page1",
      title: "", //一般情况调查问卷
      elements: [
        {
          name: "height",
          title: "您的身高__cm?",
          type: "text",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 50,
          max: 200,
        },
        {
          name: "weight",
          title: "您当前的体重__kg？",
          type: "text",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 20,
          max: 200,
        },
        {
          name: "isSmoking",
          title: "您目前是否吸烟？",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 0, text: "不吸烟" },
            { value: 2, text: "既往吸烟" },
            { value: 1, text: "目前吸烟" },
          ],
        },
        // {
        //   visibleIf: "{isSmoking} = 2",
        //   name: "quitSmokeTime",
        //   title: "已戒烟多久？",
        //   type: "text",
        //   isRequired: true,
        //   validators: [
        //     {
        //       type: "regex",
        //       text: "请输入正整数",
        //       regex: "^[+]{0,1}(\\d+)$",
        //     },
        //   ],
        //   inputType: "number",
        //   min: 1,
        //   max: 100,
        // },
        {
          type: "matrixdynamic",
          name: "quitSmokeTime",
          visibleIf: "{isSmoking} = 2",
          title: "已戒烟多久？",
          defaultValue: [
            {
              quitSmokeTimeUnit: "年",
            },
          ],
          isRequired: true,
          showHeader: false,
          columns: [
            {
              name: "quitSmokeTime",
              title: "已戒烟多久？",
              cellType: "text",
              validators: [
                {
                  type: "regex",
                  text: "请输入正整数",
                  regex: "^[+]{0,1}(\\d+)$",
                },
              ],
              inputType: "number",
              min: 1,
              max: 100,
              step: 1,
              isRequired: true,
              placeholder: "请输入数字",
            },
            {
              name: "quitSmokeTimeUnit",
              title: "单位",
              cellType: "dropdown",
              isRequired: true,
              minWidth: "100px",
              width: "300px",
              placeholder: "请选择单位",
            },
          ],
          choices: ["年", "月", "周", "日"],
          allowAddRows: false,
          allowRemoveRows: false,
          rowCount: 1,
        },
        {
          visibleIf: "{isSmoking} = 2",
          name: "yearsOfsmokePeriodBefore",
          title: "既往吸烟__年？",
          type: "text",
          defaultValue: 1,
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 1,
          max: 100,
        },
        {
          visibleIf: "{isSmoking} = 2",
          name: "cigarettesPerDayBefore",
          title: "既往一天抽烟__支？",
          type: "text",
          defaultValue: 1,
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 1,
          max: 50,
        },
        {
          visibleIf: "{isSmoking} = 1",
          name: "yearsOfsmokePeriodNow",
          title: "目前吸烟__年？",
          type: "text",
          defaultValue: 1,
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 1,
          max: 100,
        },
        {
          visibleIf: "{isSmoking} = 1",
          name: "cigarettesPerDayNow",
          title: "目前一天抽烟__支？",
          type: "text",
          defaultValue: 1,
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 1,
          max: 50,
        },
        {
          visibleIf: "{isSmoking} = 1",
          name: "isWillingToQuitSmock",
          title: "是否愿意戒烟",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 1, text: "愿意" },
            { value: 0, text: "不愿意" },
          ],
        },
        {
          name: "isDrinking",
          title: "您目前是否饮酒？",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 1, text: "是" },
            { value: 0, text: "从不饮酒" },
            { value: 2, text: "已戒酒" },
          ],
        },
        {
          visibleIf: "{isDrinking} = 1",

          name: "yearsOfDrinkPeriodNow",
          title: "目前饮酒__年？",
          type: "text",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 100,
        },
        // {
        //   visibleIf: "{isDrinking} = 1",

        //   name: "drinkingFrequency",
        //   title: "平均多久喝一次？",
        //   type: "text",
        //   isRequired: true,
        //   inputType: "number",
        //   min: 1,
        //   max: 100,
        // },
        {
          type: "matrixdynamic",
          name: "drinkingFrequency",
          visibleIf: "{isDrinking} = 1",
          title: "平均多久喝一次？",
          defaultValue: [
            {
              drinkingFrequencyUnit: "年",
            },
          ],
          isRequired: true,
          showHeader: false,
          columns: [
            {
              name: "drinkingFrequency",
              title: "平均多久喝一次？",
              cellType: "text",
              validators: [
                {
                  type: "regex",
                  text: "请输入正整数",
                  regex: "^[+]{0,1}(\\d+)$",
                },
              ],
              inputType: "number",
              min: 1,
              max: 100,
              step: 1,
              isRequired: true,
              placeholder: "请输入数字",
            },
            {
              name: "drinkingFrequencyUnit",
              title: "单位",
              cellType: "dropdown",
              isRequired: true,
              minWidth: "100px",
              width: "300px",
              placeholder: "请选择单位",
            },
          ],
          choices: ["年", "月", "周", "日"],
          allowAddRows: false,
          allowRemoveRows: false,
          rowCount: 1,
        },
        {
          visibleIf: "{isDrinking} = 1",

          name: "winesType",
          title: "您喝酒的类型？",
          type: "checkbox",
          isRequired: true,
          choices: [
            { value: "beer", text: "啤酒" },
            { value: "liquor", text: "白酒" },
          ],
        },
        {
          name: "beerAmount",
          title: "平均一次喝啤酒__ml（50ml=1两）？",
          type: "text",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          visibleIf: "{winesType} contains beer",
        },
        {
          name: "liquorAmount",
          title: "平均一次喝白酒__ml（50ml=1两）？",
          type: "text",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          visibleIf: "{winesType} contains liquor",
        },
        // {
        //   visibleIf: "{isDrinking} = 2",
        //   name: "quitDrinkTime",
        //   title: "已戒酒多久？",
        //   type: "text",
        //   isRequired: true,
        //   inputType: "number",
        //   min: 1,
        //   max: 100,
        // },
        {
          type: "matrixdynamic",
          name: "quitDrinkTime",
          visibleIf: "{isDrinking} = 2",
          title: "已戒酒多久？",
          defaultValue: [
            {
              quitDrinkTimeUnit: "年",
            },
          ],
          isRequired: true,
          showHeader: false,
          columns: [
            {
              name: "quitDrinkTime",
              title: "已戒酒多久？",
              cellType: "text",
              validators: [
                {
                  type: "regex",
                  text: "请输入正整数",
                  regex: "^[+]{0,1}(\\d+)$",
                },
              ],
              inputType: "number",
              min: 1,
              max: 100,
              step: 1,
              isRequired: true,
              placeholder: "请输入数字",
            },
            {
              name: "quitDrinkTimeUnit",
              title: "单位",
              cellType: "dropdown",
              isRequired: true,
              minWidth: "100px",
              width: "300px",
              placeholder: "请选择单位",
            },
          ],
          choices: ["年", "月", "周", "日"],
          allowAddRows: false,
          allowRemoveRows: false,
          rowCount: 1,
        },
        {
          visibleIf: "{isDrinking} = 2",
          name: "yearsOfDrinkBefore",
          title: "曾经饮酒多少年？",
          type: "text",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 100,
        },
        // {
        //   visibleIf: "{isDrinking} = 2",
        //   name: "drinkingFrequencyBefore",
        //   title: "既往平均多久喝一次？",
        //   type: "text",
        //   isRequired: true,
        //   inputType: "number",
        //   min: 1,
        //   max: 100,
        // },
        {
          type: "matrixdynamic",
          name: "drinkingFrequencyBefore",
          visibleIf: "{isDrinking} = 2",
          title: "既往平均多久喝一次？",
          defaultValue: [
            {
              drinkingFrequencyBeforeUnit: "年",
            },
          ],
          isRequired: true,
          showHeader: false,
          columns: [
            {
              name: "drinkingFrequencyBefore",
              title: "既往平均多久喝一次？",
              cellType: "text",
              validators: [
                {
                  type: "regex",
                  text: "请输入正整数",
                  regex: "^[+]{0,1}(\\d+)$",
                },
              ],
              inputType: "number",
              min: 1,
              max: 100,
              step: 1,
              isRequired: true,
              placeholder: "请输入数字",
            },
            {
              name: "drinkingFrequencyBeforeUnit",
              title: "单位",
              cellType: "dropdown",
              isRequired: true,
              minWidth: "100px",
              width: "300px",
              placeholder: "请选择单位",
            },
          ],
          choices: ["年", "月", "周", "日"],
          allowAddRows: false,
          allowRemoveRows: false,
          rowCount: 1,
        },
        {
          visibleIf: "{isDrinking} = 2",
          name: "winesTypeBefore",
          title: "您既往喝酒的类型？",
          type: "checkbox",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          choices: [
            { value: "beer", text: "啤酒" },
            { value: "liquor", text: "白酒" },
          ],
        },
        {
          name: "beerAmountBefore",
          title: "既往平均一次喝啤酒__ml（50ml=1两）？",
          type: "text",
          isRequired: true,
          inputType: "number",
          visibleIf: "{winesTypeBefore} contains beer",
        },
        {
          name: "liquorAmountBefore",
          title: "既往平均一次喝白酒__ml（50ml=1两）？",
          type: "text",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          visibleIf: "{winesTypeBefore} contains liquor",
        },
        {
          name: "dietaryHabit",
          title: "您目前的饮食习惯？",
          type: "checkbox",
          isRequired: true,
          choices: [
            { value: "preferVegetable", text: "爱吃蔬菜" },
            { value: "preferFruit", text: "爱吃水果" },
            {
              value: "preferFatty",
              text: "爱吃肥肉、油炸食品、动物内脏等",
            },
            { value: "preferSweet", text: "吃的较甜" },
            { value: "preferSalt", text: "吃的较咸" },
          ],
        },
        {
          name: "sportPerWeek",
          title:
            "您目前每周的运动习惯？（指的是散步、跑步、骑车、游泳、爬山 、打球、跳舞等运动）",
          type: "radioGroup",
          isRequired: true,
          choices: [
            { value: 0, text: "不运动" },
            { value: 1, text: "每周运动1天" },
            { value: 2, text: "每周运动2天" },
            { value: 3, text: "每周运动3天" },
            { value: 4, text: "每周运动4天" },
            { value: 5, text: "每周运动5天" },
            { value: 6, text: "每周运动6天" },
            { value: 7, text: "每周运动7天" },
          ],
        },
        {
          visibleIf: "{sportPerWeek} > 0",
          name: "sportTime",
          title: "平均每次运动__分钟？",
          type: "text",
          isRequired: true,
          inputType: "number",
          min: 1,
          max: 500,
        },
        {
          name: "medicalExpenditureType",
          title: "您的医疗支出方式？",
          type: "radioGroup",
          isRequired: true,
          choices: [
            { value: 1, text: "城镇职工基本医疗保险" },
            { value: 2, text: "城镇居民基本医疗保险" },
            { value: 3, text: "离休干部医疗保险" },
            { value: 4, text: "新农合医疗保险" },
            { value: 5, text: "商业保险" },
            { value: 6, text: "自费" },
          ],
        },
        {
          name: "educationBackground",
          title: "您的文化水平？",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 1, text: "初中（中专）及以下" },
            { value: 2, text: "高中(大专)" },
            { value: 3, text: "大学（大专 ）" },
            { value: 4, text: "研究生及以上" },
          ],
        },
        {
          name: "employmentStatus",
          title: "您的就业情况？",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 1, text: "全职工作" },
            { value: 2, text: "待业状态" },
            { value: 3, text: "退休" },
            { value: 4, text: "其他" },
          ],
        },
        {
          name: "fimaryIncome",
          title: "您的家庭收入水平？",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 1, text: "每月5000以下" },
            { value: 2, text: "每月5000-10000之间" },
            { value: 3, text: "每月10000以上" },
          ],
        },
        {
          name: "maritalStatus",
          title: "您的婚姻状况？",
          type: "radiogroup",
          isRequired: true,
          choices: [
            { value: 1, text: "未婚" },
            { value: 2, text: "已婚" },
            { value: 3, text: "离异" },
            { value: 4, text: "丧偶" },
          ],
        },
      ],
    },
  ],
};

// 只收集一次的问题数据的key
export const onlyOneTimesData = [
  "height",
  "isSmoking",
  "quitSmokeTime",
  "yearsOfsmokePeriodBefore",
  "cigarettesPerDayBefore",
  "yearsOfsmokePeriodNow",
  "isWillingToQuitSmock",
  "yearsOfDrinkPeriodNow",
  "isDrinking",
  "quitDrinkTime",
  "yearsOfDrinkBefore",
  "drinkingFrequencyBefore",
  "winesTypeBefore",
  "beerAmountBefore",
  "liquorAmountBefore",
  "medicalExpenditureType",
  "educationBackground",
  "employmentStatus",
  "fimaryIncome",
  "maritalStatus",
];

// BMI计算
function BMINum(weight, height) {
  let heightSquared = (height * height) / 10000;
  let BMI = (weight / heightSquared).toFixed(1);
  return {
    BMI: Number(BMI),
    heightSquared,
  };
}

// BMI对应结论:BMI<18.5为过低，18.5≤BMI≤23.9为正常，24≤BMI≤27.9为超重，BMI>27.9为肥胖
function BMIResultMessage(BMI, heightSquared) {
  let normalMin = (18.5 * heightSquared).toFixed(1);
  let normalMax = (23.9 * heightSquared).toFixed(1);
  let message = {
    thin: `您的体重低于正常范围，请注意饮食营养摄入，您的正常体重范围是:${normalMin}-${normalMax}`,
    overweight: `您目前体重指${BMI}，属于超重/肥胖范围，肥胖是增加心血管疾病发生的风险因素请控制体重，您的正常体重范围是${normalMin}-${normalMax}。`,
  };
  let msg = "";
  if (BMI < 18.5) {
    msg = message.thin;
  }
  if (BMI >= 24) {
    msg = message.overweight;
  }
  return msg;
}
// 目前饮酒结论
const drinkResultMessage = {
  drink:
    "服用药物期间请不要饮酒，酒精可能会影响药物效果，或有增加药物副作用的风险",
};

// 目前吸烟结论
const smokingResultMessage = {
  smoking: "吸烟有害健康，对心血管疾病的恢复及预后非常不利，请尽快戒烟",
};

// 饮食习惯结论
// const dietaryHabitResultMessage = {
//   fruitOrVegetable:
//     "每日保持摄入多样的蔬菜瓜果对疾病预后和身体健康能够起到很大的帮助。推荐每日蔬菜摄入300克，水果摄入200-250克。这些食物富含维生素，纤维和抗氧化物质，对于维护心脏健康至关重请尽量保持蔬菜和水果的多样性，以确保您获得各种营养素的好处。",
//   stodge:
//     "动物内脏及油炸食品不利于您的血脂控制，会增加心血管疾病发生的风险。为了减少心脏病的风险，请减少这类食物的摄入。",
//   sweetOrSalty:
//     "高盐高糖饮食容易加重心脏负担，建议您选择低盐低糖的饮食，过多的盐和糖摄入可能导致高血压和糖尿病等问题，这些问题会影响心脏健康。",
// };
function dietaryHabitResultMessage(dietaryHabit) {
  let message = {
    fruitOrVegetable:
      "每日保持摄入多样的蔬菜瓜果对疾病预后和身体健康能够起到很大的帮助。推荐每日蔬菜摄入300克，水果摄入200-250克。这些食物富含维生素，纤维和抗氧化物质，对于维护心脏健康至关重请尽量保持蔬菜和水果的多样性，以确保您获得各种营养素的好处。",
    stodge:
      "动物内脏及油炸食品不利于您的血脂控制，会增加心血管疾病发生的风险。为了减少心脏病的风险，请减少这类食物的摄入。",
    sweetOrSalty:
      "高盐高糖饮食容易加重心脏负担，建议您选择低盐低糖的饮食，过多的盐和糖摄入可能导致高血压和糖尿病等问题，这些问题会影响心脏健康。",
  };
  let arr = [];
  if (
    dietaryHabit.some((re) => re == "preferVegetable" || re == "preferFruit")
  ) {
    arr.push(message.fruitOrVegetable);
  }
  if (dietaryHabit.some((re) => re == "preferFatty")) {
    arr.push(message.stodge);
  }
  if (dietaryHabit.some((re) => re == "preferSweet" || re == "preferSalt")) {
    arr.push(message.sweetOrSalty);
  }
  return arr.join("</br>");
}

// 每周运动时长结论
function sportPerWeekTimeResultMessage(sportPerWeek, sportTime) {
  // 每周运动时长=每周运动天数*平均每次运动时长
  let sportPerWeekTime = sportPerWeek * sportTime;
  let message = {
    bad: "适量有氧运动和身体活动可以改善心肌缺血，降低血栓发生率和猝死风险，请避免长卧床和久坐。建议您每周进行 3-5 次总共至少 150 分钟的有氧运动会更有利于身体恢复。具体的运建议请您联系哈瑞特线上医生获取。",
    ordinary:
      "有运动习惯很好，建议您每周进行 3-5 次总共不少于 150 分钟的有氧运动会更有利于体恢复。具体的运动建议请您联系哈瑞特线上医生获取。",
    good: "您的运动习惯很好，请继续保持。具体的运动建议请您联系哈瑞特线上医生获取",
  };
  let msg;
  if (sportPerWeekTime <= 60) {
    msg = message.bad;
  } else if (sportPerWeek >= 3 || sportPerWeekTime >= 150) {
    msg = message.good;
  } else {
    msg = message.ordinary;
  }
  return msg;
}

function allScore(data) {
  let { BMI, heightSquared } = BMINum(data.weight, data.height);
  // console.log(BMI, heightSquared);
  // console.log(BMIResultMessage(BMI, heightSquared));
  // console.log(sportPerWeekTimeResultMessage(data.sportPerWeek, data.sportTime));
  // console.log(dietaryHabitResultMessage(data.dietaryHabit));
  // 此问卷有5个结论：BMI、饮酒史、吸烟史、饮食习惯、运动
  let resultInfo = {
    BMIResultMessage: BMIResultMessage(BMI, heightSquared),
    drinkResultMessage: data.isDrinking == 1 ? drinkResultMessage.drink : "",
    smokingResultMessage:
      data.isSmoking == 1 ? smokingResultMessage.smoking : "",
    sportPerWeekTimeResultMessage: sportPerWeekTimeResultMessage(
      data.sportPerWeek,
      data.sportTime
    ),
    dietaryHabitResultMessage: dietaryHabitResultMessage(data.dietaryHabit),
  };
  let specialData = {};
  for (let i = 0; i < onlyOneTimesData.length; i++) {
    specialData[onlyOneTimesData[i]] = data[onlyOneTimesData[i]];
  }
  return {
    result: resultInfo,
    specialData,
  };
}

export const calculator = (data) => {
  let resultInfo = allScore(data);
  return {
    conclusion: resultInfo.result, //结论
    score: "", //分数
    answerInfo: data, //回答答案
    desc: resultInfo.specialData, //问卷特殊数据
  };
};
