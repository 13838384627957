<template>
  <div>
    <!-- <el-button @click="test">test</el-button> -->
    <Survey :survey="survey" />
  </div>
</template>
<script>
import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import { Model } from "survey-core";
import { Survey } from "survey-vue-ui";

import "survey-core/themes/layered-dark-panelless";
// import test from "./survey_theme.json";

export default {
  name: "SurveyBuilder",
  components: {
    Survey,
  },
  props: ["data", "calculator", "onlyOneTimesData"],
  data() {
    return {
      survey: null,
    };
  },
  watch: {
    onlyOneTimesData(val) {
      console.log(val);
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // 处理不再需要回答的问题
      if (this.onlyOneTimesData) {
        let testArr = [];
        for (let i = 0; i < this.data.pages[0].elements.length; i++) {
          const el = this.data.pages[0].elements[i];
          if (!this.onlyOneTimesData.some((re) => re == el.name)) {
            testArr.push(el);
          }
        }
        console.log(testArr, this.data);
        this.data.pages[0].elements = testArr;
      }

      this.survey = new Model(this.data);
      const themeTest = require("./survey_theme.json");
      this.survey.applyTheme(themeTest);
      console.log("survey");
      this.survey.locale = "zh-cn";
      // this.survey.onComplete.add((sender) => {
      //   console.log(sender);
      //   if (this.calculator) {
      //     console.log("///didi");
      //     this.calculator(sender.data);
      //   }
      // });
      // set deisplay mode

      // 一般情况调差问卷

      // this.survey.data = {
      //   height: 170, //身高
      //   weight: 70, //体重
      //   isSmoking: 2, //是否吸烟：0=不吸烟，2=既往吸烟，1=目前吸烟

      //   //// 目前吸烟相关问题答案数据
      //   // yearsOfsmokePeriodNow: 35, //目前吸烟多少年
      //   // cigarettesPerDayNow: 15, //目前一天抽烟多少支
      //   // isWillingToQuitSmock: 0, //是否愿意戒烟

      //   //// 既往吸烟相关问题答案数据
      //   yearsOfsmokePeriodBefore: 20, //既往吸烟多少年
      //   cigarettesPerDayBefore: 19, //既往一天吸烟多少支
      //   quitSmokeTime: [{ quitSmokeTime: 10, quitSmokeTimeUnit: "年" }], //已戒烟多久

      //   isDrinking: 2, //是否饮酒，0=不饮酒，2=已戒酒，1=是

      //   //// 目前饮酒相关问题答案数据
      //   // yearsOfDrinkPeriodNow: 30, //目前饮酒多少年
      //   // drinkingFrequency: [
      //   //   { drinkingFrequency: 5, drinkingFrequencyUnit: "日" },
      //   // ], //平均多久喝一次
      //   // winesType: ["beer", "liquor"], //喝酒类型
      //   // beerAmount: 600, //啤酒平均一次喝多少ml
      //   // liquorAmount: 100, //白酒平均一次喝多少ml

      //   //// 已戒酒相关问题答案数据
      //   yearsOfDrinkBefore: 20,
      //   quitDrinkTime: [{ quitDrinkTime: 5, quitDrinkTimeUnit: "年" }], //已戒酒多久
      //   drinkingFrequencyBefore: [
      //     { drinkingFrequencyBefore: 5, drinkingFrequencyBeforeUnit: "日" },
      //   ], //既往平均多久喝一次
      //   winesTypeBefore: ["beer", "liquor"], //既往喝酒类型
      //   beerAmountBefore: 600, //既往啤酒平均一次喝多少ml
      //   liquorAmountBefore: 100, //既往白酒平均一次喝多少ml

      //   dietaryHabit: ["preferFruit", "preferSalt"], //目前的饮食习惯
      //   educationBackground: 1, //文化水平
      //   employmentStatus: 4, //就业情况
      //   fimaryIncome: 1, //家庭收入水平
      //   maritalStatus: 2, //婚姻状况
      //   medicalExpenditureType: 4, //医疗支出方式
      //   sportPerWeek: 2, //平均每周运动多少天
      //   sportTime: 70, //平均每次运动多少分钟
      // };

      // 2 匹兹堡睡眠质量指数 PSQI
      // this.survey.data = {
      //   cough: "0",
      //   difficultyFallingAsleep: "1",
      //   disturbanceInRespiration: "0",
      //   drowsiness: "2",
      //   drugHypnosis: "0",
      //   earlyAwakening: "1",
      //   energy: "1",
      //   feelCold: "0",
      //   horribleDream: "0",
      //   nightToilet: "1",
      //   otherEffects: "1",
      //   pain: "0",
      //   sackTime: "20:00",
      //   sensibleHeat: "0",
      //   sleepDuration: "0",
      //   sleepLongth: 7,
      //   sleepQuality: "2",
      //   wakeupTime: "08:00",
      // };

      //3 生活质量调查表
      // this.survey.data = {
      //   heavyPhysicalActivity: "2",
      //   aFewFloor: "3",
      //   beCompletelyExhausted: "4",
      //   bendDown: "2",
      //   bodyAches: "2",
      //   bodyAchesEffect: "2",
      //   boredom: "3",
      //   calmness: "3",
      //   carelessnessEmo: "1",
      //   dailyActivities: "3",
      //   downcast: "4",
      //   dressOneself: "2",
      //   easySick: "2",
      //   emotionsAffectSocialInteraction: "4",
      //   fullOfEnergy: "2",
      //   goBad: "3",
      //   health: "3",
      //   healthCondition: "1",
      //   healthConditionBefore: "3",
      //   inExcellentCondition: "4",
      //   increaseOfDifficulties: "1",
      //   influenceActivity: "3",
      //   insensitivityToPleasure: "2",
      //   lifeRich: "4",
      //   longDistanceWalk: "2",
      //   middleDistanceWalk: "2",
      //   moderateActivity: "2",
      //   noeFloor: "2",
      //   reduceTime: "2",
      //   reduceTimeEmo: "2",
      //   sensitivity: "2",
      //   shortWalk: "2",
      //   sunbeam: "5",
      //   unfinishedBusiness: "1",
      //   unfinishedBusinessEmo: "1",
      //   workLimit: "2",
      // };

      // 4 焦虑筛查量表
      // this.survey.data = {
      //   horribleFeel: "1",
      //   nervous: "1",
      //   restless: "1",
      //   testiness: "2",
      //   uneasy: "0",
      //   worry: "1",
      //   worryTooMuch: "1",
      // };

      //5 抑郁筛查量表
      // this.survey.data = {
      //   bradypragia: "2",
      //   commitSuicide: "2",
      //   dispirited: "1",
      //   feelBad: "2",
      //   inappetence: "1",
      //   indifference: "1",
      //   poorConcentration: "0",
      //   sleepBadly: "1",
      //   tired: "2",
      // };
      // this.survey.mode = "display";
    },
    saveSurveyResult() {
      console.log("//");
      console.log(this.survey.data);
      if (this.survey.completeLastPage()) {
        this.calculator(this.survey.data);
      }
    },
  },
};
</script>
<style>
.sd-footer {
  display: none;
}
</style>
